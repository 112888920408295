import React from 'react';
import { FormattedMessage } from 'react-intl';
import { P } from '@dnb/eufemia/elements';
import Head from '@dnb/helmet';
import { SimpleHeader } from '@dnb/header';
import translationKeys from '../lib/translationKeys/general';

export default function Translations() {
  const title = 'Web Framework Translation demo';
  return (
    <div>
      <Head>
        <title>{title}</title>
      </Head>
      <SimpleHeader
        title={title}
        subtitle="Demonstration Gatsby App using Web Framework components"
      />
      <P>
        <FormattedMessage id={translationKeys.dnb_hello_world} />
      </P>
      <P>
        <FormattedMessage id={translationKeys.dnb_try_changing_lang} />
      </P>
    </div>
  );
}
